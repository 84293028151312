<template>
  <container class="p-1">
    <div class="d-flex justify-content-end mb-2">
      <div class="mr-2 d-inline-flex flex-column">
        <label><b>Search Type</b></label>
        <DxSwitch
          v-tooltip="searchType ? 'Macro search' : 'Free text search'"
          switched-on-text="Macro"
          switched-off-text="Text"
          v-model="searchType"
          width="4rem"
        />
      </div>
      <div class="mr-2 d-inline-flex flex-column" v-if="searchType">
        <label><b>Starts With</b></label>
        <DxSwitch
          v-tooltip="startsWith ? 'Macro Starts With' : 'Contains'"
          switched-on-text="Starts With"
          switched-off-text="Contains"
          v-model="startsWith"
          width="5rem"
        />
      </div>
      <div v-if="permissions.MacroViewAll" class="mr-2 d-inline-flex flex-column">
        <label><b>All Macros</b></label>
        <DxSwitch v-model="macroViewAll" :key="macroViewAll" />
      </div>
      <div class="mr-2 d-inline-flex flex-column">
        <label><b>Show Expired</b></label>
        <DxSwitch v-model="viewExpired" :key="viewExpired" />
      </div>
    </div>
    <DxGridWithSearch
      :columnChooser="true"
      v-if="permissions.MacroView"
      :columns="columns"
      title="Macro Configuration"
      :dataSource="dataSource"
      noDataText="No macros found."
      ref="macroGrid"
      :pageSize="12"
      @initialized="initializeGrid"
      :toolbar="toolbar"
      :searchType="searchType"
      :startsWith="startsWith"
      :searchPanel="searchPanel"
      :onOptionChanged="onOptionChanged"
    >
      <template v-slot:extraActions>
        <div class="d-flex align-items-center">
          <AddBtn
            v-if="anyCreatePermission"
            @click="toggleForm"
            :icon="isConfigOpen ? 'times' : 'plus'"
            :text="isConfigOpen ? 'Close' : 'Add'"
          />
        </div>
      </template>
      <template v-slot:actions="{ data }">
        <div>
          <icon-button
            v-if="editPermissions[data.data.macroType]"
            type="button"
            class="btn p-0 text-primary mx-1"
            @click="editMacro(data)"
            v-tooltip.left="'Edit this macro.'"
            icon="pen-alt"
            :data-testid="`edit-macro-${data.rowIndex}`"
          />
          <icon-button
            v-if="editPermissions[data.data.macroType]"
            type="button"
            class="btn p-0 text-primary mx-1"
            v-tooltip.left="'Clone this macro.'"
            @click="cloneMacro(data)"
            :data-testid="`clone-macro-${data.rowIndex}`"
            icon="copy"
          />
        </div>
      </template>
      <template v-slot:expirationCell="{ data }">
        <div class="align-items-center">
          <ExpiryTemplate :expiry-date="data.value" />
        </div>
      </template>
    </DxGridWithSearch>
    <!-- FORM DATA -->
    <modal :status="isConfigOpen" @close="toggleForm">
      <MacroForm
        class="p-2"
        :isClone="isClone"
        @submit="handleSubmit"
        @clear="toggleForm"
        @delete="handleSubmit"
        :selectedMacroId="selectedMacroId"
      />
    </modal>
    <modal :status="isUserExportOpen" @close="toggleUserExportPopup">
      <export-user-macros />
    </modal>
  </container>
</template>

<script>
import DxGridWithSearch from "@/components/common/DxGridWithSearch";
import MacroForm from "@/components/forms/Macros/Configuration.vue";
import MacrosApi from "@/services/macros";
import { mapState, mapGetters } from "vuex";
import { startCase, camelCase } from "lodash";
import DxSwitch from "devextreme-vue/switch";
import dropdown from "../services/dropdown";
import DataSource from "devextreme/data/data_source";
import ExpiryTemplate from "@/components/common/ExpiryTemplate.vue";
import Container from "@/components/common/Container.vue";
import Modal from "@/components/common/Modal.vue";
import IconButton from "@/components/common/IconButton.vue";
import { dateRangeFilter, getTextFromHtml } from "@/modules/helpers";
import { format } from "date-fns";
import AddBtn from "@/components/common/AddButton.vue";
import ExportUserMacros from "@/components/ExportUserMacros.vue";

export default {
  name: "MacroConfig",
  components: {
    MacroForm,
    DxGridWithSearch,
    DxSwitch,
    ExpiryTemplate,
    Container,
    Modal,
    IconButton,
    AddBtn,
    ExportUserMacros
  },
  metaInfo: {
    title: "Macro Configuration",
    titleTemplate: "IntelliPath - %s"
  },
  data() {
    return {
      macroGrid: {},
      loading: false,
      viewExpired: false,
      dropDowns: {
        CPTCodes: [],
        HoldCodes: [],
        Procedures: [],
        Images: [],
        Tags: [],
        MacroTypes: [],
        icdCodes: [],
        users: [],
        filteredResults: [],
        DiagnosisSummaries: []
      },
      dataSource: [],
      macros: MacrosApi.searchStore,
      isConfigOpen: false,
      isClone: false,
      selectedMacroId: null,
      macroSearch: MacrosApi.searchStore,
      macroViewAll: false,
      searchType: false,
      startsWith: false,
      isUserExportOpen: false,
      searchPanelText: ""
    };
  },
  created() {
    dropdown.getMacroTypes().then(res => {
      this.dropDowns.MacroTypes = res || [];
    });
    dropdown.getDiagnosisSummaries(this.currentLab).then(res => {
      this.dropDowns.DiagnosisSummaries = res || [];
    });
    this.searchType = this.$store.state.applicationSettings.macroSearchMode;
    this.startsWith = this.$store.state.applicationSettings.macroStartsWith;
    this.createDataSource();
  },
  computed: {
    ...mapState(["currentUser", "currentLab"]),
    ...mapGetters(["permissions"]),

    editPermissions() {
      return {
        0: this.permissions.MacroResultsCreateEdit,
        1: this.permissions.MacroGrossCreateEdit,
        2: this.permissions.MacroPanelCreateEdit,
        3: this.permissions.MacroProtocolCreateEdit,
        4: this.permissions.MacroGeneralCreateEdit,
        5: this.permissions.MacroDiagnosisCreateEdit,
        6: this.permissions.MacroMicroscopicCreateEdit,
        7: this.permissions.MacroSpecimenNoteCreateEdit
      };
    },
    anyCreatePermission() {
      const {
        MacroResultsCreateEdit,
        MacroGrossCreateEdit,
        MacroPanelCreateEdit,
        MacroDiagnosisCreateEdit,
        MacroMicroscopicCreateEdit,
        MacroSpecimenNoteCreateEdit
      } = this.permissions;
      return (
        MacroResultsCreateEdit ||
        MacroGrossCreateEdit ||
        MacroPanelCreateEdit ||
        MacroDiagnosisCreateEdit ||
        MacroMicroscopicCreateEdit ||
        MacroSpecimenNoteCreateEdit
      );
    },
    columns() {
      const macroTypes = this.dropDowns.MacroTypes;
      const diagnosisSummaries = this.dropDowns.DiagnosisSummaries;
      return [
        {
          caption: "Actions",
          type: "buttons",
          visibleIndex: 0,
          cellTemplate: "actions"
        },
        {
          dataField: "macroName",
          caption: "Macro Name",
          sortOrder: "asc",
          sortIndex: 1,
          dataType: "string",
          calculateFilterExpression: (selector, comparisonOperator) => {
            return [
              "macroName",
              comparisonOperator ? comparisonOperator : this.startsWith ? "startswith" : "contains",
              selector
            ];
          }
        },
        {
          dataField: "macroType",
          caption: "Type",
          lookup: {
            dataSource: macroTypes,
            displayExpr: "displayName",
            valueExpr: "id"
          }
        },
        {
          dataField: "diagnosisSummaryId",
          caption: "Diagnosis Summary",
          lookup: {
            dataSource: diagnosisSummaries,
            displayExpr: "displayText",
            valueExpr: "id"
          }
        },
        {
          dataField: "effectiveOn",
          dataType: "date",
          sortOrder: "desc",
          sortIndex: 1,
          caption: "Effective On"
        },
        {
          dataField: "expiresOn",
          dataType: "date",
          caption: "Expires On",
          cellTemplate: "expirationCell"
        },
        // {
        //   dataField: "usersListString",
        //   dataType: "string",
        //   caption: "Users",
        //   width: "250px",
        //   calculateCellValue(data) {
        //     if ([1, 5, 6, 0, 7, 4].includes(data.macroType)) {
        //       return data.usersListString;
        //     }
        //     return "All";
        //   }
        // },
        {
          dataType: "string",
          calculateCellValue: data => {
            if (data.diagnosis) {
              return getTextFromHtml(data.diagnosis);
            }
            return "";
          },
          dataField: "diagnosis",
          encodeHtml: false,
          visible: false,
          showInColumnChooser: false
        },
        {
          dataType: "string",
          calculateCellValue: data => {
            if (data.generalText) {
              return getTextFromHtml(data.generalText);
            }
            return "";
          },
          dataField: "generalText",
          encodeHtml: false,
          visible: false,
          showInColumnChooser: false
        },
        { dataType: "string", dataField: "description" }
      ];
    },
    toolbar() {
      return {
        items: [
          {
            location: "after",
            template: "extraActions"
          },
          {
            widget: "dxButton",
            options: {
              icon: "fa fa-file-excel",
              onClick: this.exportToExcel,
              hint: "Click to export as excel file",
              elementAttr: {
                class: "icon-color"
              },
              text: "Export to Excel"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          },
          {
            widget: "dxButton",
            options: {
              icon: "fa fa-user",
              onClick: this.exportUserMacros,
              hint: "Click to export macros for user(s)",
              elementAttr: {
                class: "icon-color"
              },
              text: "Export to Excel"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          },
          // Delete button removed.
          // {
          //   widget: "dxButton",
          //   options: {
          //     icon: "fa fa-trash-alt",
          //     onClick: this.deleteSelectedMacros,
          //     hint: "Click to delete selected macros",
          //     elementAttr: {
          //       class: "icon-delete"
          //     },
          //     text: "Delete macros"
          //   },
          //   locateInMenu: "auto",
          //   showText: "inMenu",
          //   visible: this.permissions.MacroDelete
          // }
        ]
      };
    },
    searchPanel() {
      return {
        searchVisibleColumnsOnly: this.searchType,
        searchOperation: this.startsWith ? "startswith" : "contains",
        visible: true,
        width: "300px"
      };
    }
  },
  watch: {
    macroViewAll: {
      handler() {
        this.createDataSource();
        this.macroGrid.clearFilter("dataSource");
      }
    },
    viewExpired: {
      handler() {
        this.updateFilter();
      }
    }
  },
  methods: {
    async createDataSource() {
      let store = [];
      let filter = null;
      if (this.macroViewAll) {
        store = this.macros;
      } else {
        store = await MacrosApi.getMacrosByUser(this.currentUser.id);
      }
      if (!this.viewExpired) {
        filter = dateRangeFilter("effectiveOn", "expiresOn");
      }
      const startsWith = this.startsWith;
      this.dataSource = new DataSource({
        store,
        key: "macroId",
        filter,
        searchOperation: startsWith ? "startswith" : "contains"
      });
    },

    initializeGrid({ component }) {
      this.macroGrid = component;
      component.on("exporting", data => {
        data.fileName = `${format(new Date(), "MM/dd/yyy")}_Macro_Grid`;
        data.component.columnOption("diagnosis", "visible", true);
        data.component.columnOption("generalText", "visible", true);
      });
      component.on("exported", data => {
        data.component.columnOption("diagnosis", "visible", false);
        data.component.columnOption("generalText", "visible", false);
      });
    },
    async toggleForm() {
      if (this.isConfigOpen) {
        const answer = await window.confirm("You may have unsaved changes, are you sure?");
        if (!answer) {
          return;
        }
      }
      this.selectedMacroId = null;
      this.isConfigOpen = !this.isConfigOpen;
      this.isClone = false;
    },
    handleSubmit() {
      this.isConfigOpen = false;
      this.isClone = false;
      return this.macroGrid.refresh(true);
    },
    async editMacro({ data }) {
      if (this.isConfigOpen) {
        const answer = await window.confirm("You may have unsaved changes, are you sure?");
        if (!answer) {
          return;
        }
      }
      this.isConfigOpen = true;
      this.selectedMacroId = data?.macroId;
    },
    async cloneMacro({ data }) {
      if (this.isConfigOpen) {
        const answer = await window.confirm("You may have unsaved changes, are you sure?");
        if (!answer) {
          return;
        }
      }
      this.isConfigOpen = true;
      this.isClone = true;
      this.selectedMacroId = data?.macroId;
    },
    displayDiagnosis({ diagnosisSummaryId }) {
      const { DiagnosisSummaries } = this.dropDowns;
      if (DiagnosisSummaries && diagnosisSummaryId) {
        const diagnosis = DiagnosisSummaries.find(e => e.id === diagnosisSummaryId);
        if (diagnosis) {
          return diagnosis.displayText;
        }
      }
      return "N/A";
    },
    displayMacroType(macro) {
      const { macroType } = macro;
      const macroTypeObject = this.dropDowns.MacroTypes.find(e => e.id === macroType);
      if (macroTypeObject) {
        return startCase(camelCase(macroTypeObject.displayName));
      }
      return "N/A";
    },
    isEnabled(field) {
      if (this.macro.macroType) {
        const { displayName } = this.dropDowns.MacroTypes.find(e => e.id === this.macro.MacroType);
        return this.fieldMap[displayName].includes(field);
      }
      return true;
    },
    diagnosisSummaryDisplay(id) {
      const diagnosis = this.dropDowns?.DiagnosisSummaries?.find(e => {
        return e.id === id;
      });

      if (diagnosis) {
        return diagnosis.displayName || diagnosis.displayText;
      } else {
        return "N/A";
      }
    },
    exportToExcel() {
      this.macroGrid.exportToExcel(false);
    },
    exportUserMacros() {
      this.isUserExportOpen = true;
    },
    toggleUserExportPopup() {
      this.isUserExportOpen = !this.isUserExportOpen;
    },
    onOptionChanged(event) {
      if (this.startsWith && event.name === "searchPanel" && event.value !== event.previousValue) {
        this.searchPanelText = event.value;
        this.updateFilter();
      }
    },
    updateFilter() {
      const startsWithFilter = this.searchPanelText
        ? ["macroName", "startsWith", this.searchPanelText]
        : null;
      const effectiveFilter = this.viewExpired ? null : dateRangeFilter("effectiveOn", "expiresOn");
      if (startsWithFilter && effectiveFilter) {
        this.macroGrid.filter([effectiveFilter, "and", startsWithFilter]);
      } else if (effectiveFilter) {
        this.macroGrid.filter(effectiveFilter);
      } else if (startsWithFilter) {
        this.macroGrid.filter(startsWithFilter);
      } else {
        this.macroGrid.clearFilter("dataSource");
      }
      this.macroGrid.refresh();
    },
    async deleteSelectedMacros() {
      // IP-79: waiting for backend implementation before finishing this function
      const selectedMacrosData = this.macroGrid.getSelectedRowsData();
      const usedMacros = selectedMacrosData.filter(macro => macro.hasBeenUsed);
      const macrosForDeletion = selectedMacrosData.filter(macro => !macro.hasBeenUsed);
      if (usedMacros.length) {
        window.alert(
          `The following macros are currently being used and cannot be deleted. Please use the Expires On functionality if you wish to remove them. <br>${usedMacros
            .map(function (e) {
              return "<br>" + e.macroName;
            })
            .join("")}`
        );
        return this.macroGrid.refresh();
      }
      if (!macrosForDeletion.length) {
        window.alert("Please select macros to be deleted.");
        return;
      }
      const confirmDelete = await window.confirm(
        `Are you sure you want to delete these macros? This action cannot be undone.<br>${macrosForDeletion
          .map(function (e) {
            return "<br>" + e.macroName;
          })
          .join("")}`
      );
      if (!confirmDelete) {
        return;
      }
      const macroIds = macrosForDeletion.map(e => e.macroId);
      const response = await MacrosApi.deleteMacros({ macroIds, labId: this.currentLab });
      const successfulDeletions = response.filter(e => e?.wasDeleted);
      const errors = response.filter(e => !e?.wasDeleted);
      if (successfulDeletions.length) {
        if (!errors.length) {
          window.alert(`Macro${successfulDeletions.length > 1 ? "s" : ""} deleted.`);
        } else {
          window.alert(
            `Successfully deleted the following macros:<br>${successfulDeletions
              .map(e => e.macroName)
              .join("<br>")}<br>The following macros were unable to be deleted:<br><br>${errors
              .map(e => e.macroName + ": " + e.reason)
              .join("<br>")}`
          );
        }
        this.macroGrid.refresh();
      } else {
        window.alert(
          `Error deleting macros:<br><br>${errors
            .map(e => e.macroName + ": " + e.reason)
            .join("<br>")}`
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  background-color: $white;
}
.button_actions {
  margin: 0 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 80%;
  border: 1px solid $primary;
  text-align: center;
  height: 30px;
  width: 30px;
  color: $primary;
  &.filled {
    background-color: $primary;
    color: white;
  }

  &:hover {
    opacity: 0.8;
  }
}
.pagination {
  width: 15%;
  display: flex;
  justify-content: space-between;
  .btn {
    height: 25px;
    width: 25px;
    font-size: 0.75rem;
    &:disabled {
      background-color: darkgrey;
    }
  }
  .pages {
    display: flex;
    justify-content: space-between;
    width: 40%;
  }
}
.button-group {
  display: flex;
  justify-content: space-between;
}
.header-row {
  background-color: #e4e4e4;
  & > * {
    font-weight: 600;
  }
  height: 50px;
  font-size: 0.8rem;
}
table {
  border: none;
  .actions-header {
    width: 15%;
  }
  td {
    padding: 0.25rem;
  }

  .empty {
    border: none !important;
    opacity: 0;
    visibility: hidden;
  }
}
.input-icon {
  & > svg {
    position: relative;
  }
  input {
    width: 100%;
    border: none;
    height: 100%;
    &:focus {
      outline: none;
    }
  }
  .btn {
    padding: 0;
  }
  :last-child::placeholder {
    text-align: right;
  }
  & > input:active {
    border: none;
  }
  padding: 0.2rem;
  text-align: right;
  display: flex;
  font-size: 0.95rem;
  &.search {
    width: 354px;
  }
}

.pages {
  span {
    cursor: pointer;
    color: $primary;
    &:hover {
      text-decoration: underline;
    }
    &.active {
      color: darkgrey;
    }
  }
}
</style>
